import { ActionWithoutPayload } from '@interfaces/index';

export enum ApplicationActions {
  TOGGLE_STATUS_SIDEBAR = 'TOGGLE_STATUS_SIDEBAR',
}

export type IToggleStatusSidebar =
  ActionWithoutPayload<ApplicationActions.TOGGLE_STATUS_SIDEBAR>;
export type IApplicationAction = IToggleStatusSidebar;

export default ApplicationActions;
