const handleDocumentOnListener = ({ target }, height): void => {
  const inputElm = target as HTMLInputElement;
  let scrolling = null;
  let isEnd = null;

  const handleScroll = (): void => {
    if (!isEnd) {
      return;
    }

    if (scrolling) {
      clearTimeout(scrolling);
    }

    scrolling = setTimeout(() => {
      const footerPosition = (
        (document.querySelector('.footer-page') as HTMLElement) || {
          offsetTop: 0,
        }
      )?.offsetTop;

      if (window.innerHeight >= height && !footerPosition) {
        return;
      }

      if (footerPosition - 350 - window.pageYOffset > 0) {
        return;
      }

      isEnd = false;
      document?.body?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 300);
  };

  const handleScrollEnd = (): void => {
    isEnd = true;
  };

  if (inputElm.nodeName !== 'INPUT') {
    return;
  }

  window.addEventListener('touchend', handleScrollEnd);
  window.addEventListener('scroll', handleScroll);

  const removeListeners = (): void => {
    inputElm.removeEventListener('focusout', removeListeners);
    window.removeEventListener('touchend', handleScrollEnd);
    window.addEventListener('scroll', handleScroll);
  };

  inputElm.addEventListener('focusout', removeListeners);
};

export default handleDocumentOnListener;
