import React from 'react';

import IDispatch from '@packages/provider/IDispatch';
import IReducer from '@packages/provider/IReducer';

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/ban-types,@typescript-eslint/explicit-function-return-type
export default <StateType extends {}>(
  reducer: IReducer<StateType>,
  defaultState: StateType,
  displayName?: string,
) => {
  const StateContext = React.createContext(defaultState);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const DispatchContext = React.createContext((action: any) => {
    // eslint-disable-next-line no-console
    console.log(action);
  });

  if (displayName) {
    StateContext.displayName = displayName;
  }

  let dispatchInstance: IDispatch;
  let stateInstance: StateType;

  const useState = (): StateType => React.useContext(StateContext);
  const useDispatch = (): IDispatch => React.useContext(DispatchContext);
  const getDispatch = (): IDispatch => dispatchInstance;
  const getState = (): StateType => stateInstance;

  const Provider = ({
    children,
  }: {
    children: React.ReactNode;
  }): JSX.Element => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);

    dispatchInstance = dispatch;
    stateInstance = state;

    return (
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          {children}
        </DispatchContext.Provider>
      </StateContext.Provider>
    );
  };

  return {
    useState,
    useDispatch,
    Provider,
    getDispatch,
    dispatch: ((...args) => dispatchInstance(...args)) as IDispatch,
    getState,
  };
};
