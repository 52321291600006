import React from 'react';
import intl from 'react-intl-universal';
import Spin from 'antd/lib/spin';

import { PropTypes } from './Loader.types';

const Loader = (props: PropTypes): JSX.Element => {
  const {
    children,
    tip = `${intl.get('common.loading')}...`,
    spinning,
  } = props;
  return (
    <Spin tip={tip} spinning={spinning}>
      {children}
    </Spin>
  );
};

export default Loader;
