export const TOKEN = 'air-cash-jwt';
export const LANGUAGE = 'lang';
export const LOW_BALANCE = 'low-balance';
export const POS_ID = 'pos_id';
export const LOCATION_ID = 'location_id';
export const GWG_TRAINING = 'gwg-training';
export const GWG_TRAINING_STEP = 'gwg-training_step';
export const GWG_TRAINING_EXAM_STEP = 'gwg-training_exam_step';
export const GWG_TRAINING_EXAM_ANSWERS = 'gwg-training_exam_answers';
export const GWG_TRAINING_EXAM_RESULT = 'gwg-training_exam_result';
