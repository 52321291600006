import { INotification } from '@interfaces/index';

import INotificationsActions, {
  INotificationsAction,
} from '@providers/notifications/actions/notificationsActionTypes';

export type INotificationsProviderState = {
  data: INotification[];
};

export const defaultState: INotificationsProviderState = {
  data: [],
};

export default (
  state: INotificationsProviderState,
  action: INotificationsAction,
): INotificationsProviderState => {
  switch (action.type) {
    case INotificationsActions.DELETE_NOTIFICATIONS: {
      return { data: [] };
    }
    case INotificationsActions.ADD_NOTIFICATION: {
      return { data: state.data.concat(action.payload) };
    }
    default: {
      return state;
    }
  }
};
