import isObject from 'lodash/isObject';
import map from 'lodash/map';

import ILoggerConfig from '@packages/logger/core/ILoggerConfig';
import ILogger from '@packages/logger/core/ILogger';

export default class Logger implements ILogger {
  private config: ILoggerConfig;

  constructor(config: ILoggerConfig) {
    this.config = config;
  }

  private static serialize(item: string): string {
    return isObject(item) ? JSON.stringify(item) : item;
  }

  log(...args: string[]): void {
    if (this.config.logToConsole) {
      // eslint-disable-next-line no-console
      console.log(
        `%c ${map(args, Logger.serialize).join(' ')}`,
        'color: green',
      );
    }
  }

  error(...args: string[]): void {
    if (this.config.logToConsole) {
      // eslint-disable-next-line no-console
      console.log(`%c ${map(args, Logger.serialize).join(' ')}`, 'color: red');
    }
  }

  info(...args: string[]): void {
    if (this.config.logToConsole) {
      // eslint-disable-next-line no-console
      console.log(`%c ${map(args, Logger.serialize).join(' ')}`, 'color: blue');
    }
  }

  warn(...args: string[]): void {
    if (this.config.logToConsole) {
      // eslint-disable-next-line no-console
      console.warn(
        `%c ${map(args, Logger.serialize).join(' ')}`,
        'color: #f0ad4e',
      );
    }
  }
}
