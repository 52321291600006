import { Action, ActionWithoutPayload, INotification } from '@interfaces/index';

export enum NotificationsActions {
  DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS',
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
}

export type IDeleteNotifications =
  ActionWithoutPayload<NotificationsActions.DELETE_NOTIFICATIONS>;
export type IAddNotification = Action<
  NotificationsActions.ADD_NOTIFICATION,
  INotification
>;
export type INotificationsAction = IDeleteNotifications | IAddNotification;

export default NotificationsActions;
