import { createGlobalStyle } from 'styled-components';

import device from '@helpers/breakpoints';

const GlobalStyles = createGlobalStyle`
  .admin-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .header-content {
    color: #fff;
    overflow-y: hidden;
  }

  .header-content .nav-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main-footer {
    text-align: center;
  }

  .main-content-wrapper {
    flex: 1;
    background-color: var(--surface-alt-bg);
    min-height: min-content;
  }

  .wrapper-detail {
    display: flex;
    flex-flow: column wrap;
    max-width: 600px;
    margin: 0 auto;
  }

  .ction-column {
    width: 60px;
  }

  .ant-picker-panel-container .ant-picker-panels {
    flex-wrap: wrap;
    width: max-content;
  }

  @media ${device.tablet} {
    .ant-picker-panel-container .ant-picker-panels {
      width: min-content;
    }

    .ant-menu-item:hover {
      color: var(--text) !important;
    }
  }

  @media print {
    div,
    section {
      overflow: visible !important;
    }

    .no-print {
      display: none !important;
    }
  }
`;

export default GlobalStyles;
