import React, { useEffect, useState } from 'react';

import Logger from '@packages/logger/core/coreLogger';
import Cookie from '@packages/cookie';

import { IServices, IServicesState, IContextServices } from '@interfaces/index';

const ServicesContext = React.createContext<IServices>({} as IServices);

export const useServices = (): IServices => React.useContext(ServicesContext);

const serviecsInstance: IServicesState = {} as IServicesState;

const ServicesProvider: React.FC<IContextServices> = ({ children }) => {
  const [services, setServices] = useState({ ...serviecsInstance });

  const updateServices = (
    key: keyof IServicesState,
    value: IServicesState[typeof key],
  ): void => {
    setServices((prevValue) => ({ ...prevValue, [key]: value }));
  };

  useEffect(() => {
    const options = {
      cookie: new Cookie(),
      logger: new Logger({
        logToConsole:
          (process.env.NODE_ENV && process.env.NODE_ENV === 'development') ||
          false,
      }),
    };
    setServices(options);
  }, []);

  return (
    <ServicesContext.Provider value={{ ...services, updateServices }}>
      {children}
    </ServicesContext.Provider>
  );
};

export default ServicesProvider;
