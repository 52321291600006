import { IUser } from '../../../../server/interfaces';
import { userDispatch } from '../index';

import { UserActions } from './userActionTypes';

const setUser = (user: IUser): void => {
  userDispatch({ type: UserActions.SET_USER, payload: user });
};

export default setUser;
