import createProvider from '@packages/provider/Provider';

import { IUser } from '../../../server/interfaces';

import userReducer, { IUserProviderState, defaultState } from './reducer';

export const {
  Provider: UserProvider,
  useState: useUserState,
  useDispatch: useUserDispatch,
  getDispatch: getUserDispatch,
  getState: getUserState,
  dispatch: userDispatch,
} = createProvider<IUserProviderState>(userReducer, defaultState);

export const useUser = (): IUser | null => useUserState().data;
