import { Action, ActionWithoutPayload } from '@interfaces/index';

import { IUser } from '../../../../server/interfaces';

export enum UserActions {
  SET_USER = 'LOGIN_USER',
  LOGOUT_USER = 'LOGOUT_USER',
}

export type ISetUser = Action<UserActions.SET_USER, IUser>;
export type ILogoutUserAction = ActionWithoutPayload<UserActions.LOGOUT_USER>;

export type IUserAction = ISetUser | ILogoutUserAction;

export default UserActions;
