import createProvider from '@packages/provider/Provider';

import applicationReducer, { IApplicationState, defaultState } from './reducer';

export const {
  Provider: ApplicationProvider,
  useState: useApplicationState,
  useDispatch: useApplicationDispatch,
  getDispatch: getApplicationDispatch,
  getState: getApplicationState,
  dispatch: applicationDispatch,
} = createProvider<IApplicationState>(applicationReducer, defaultState);

export const useApplication = (): IApplicationState => useApplicationState();
