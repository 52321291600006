import ApplicationActions, {
  IApplicationAction,
} from '@providers/applicationContext/actions/appActionTypes';

export type IApplicationState = {
  isMenuOpen: boolean;
};

export const defaultState: IApplicationState = {
  isMenuOpen: false,
};

export default (
  state: IApplicationState,
  action: IApplicationAction,
): IApplicationState => {
  if (action.type === ApplicationActions.TOGGLE_STATUS_SIDEBAR) {
    return { isMenuOpen: !state.isMenuOpen };
  }
  return state;
};
