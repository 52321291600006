import UserActions, {
  IUserAction,
} from '@providers/user/actions/userActionTypes';

import { IUser } from '../../../server/interfaces';

export type IUserProviderState = {
  data: IUser | null;
};

export const defaultState: IUserProviderState = {
  data: null,
};

export default (
  state: IUserProviderState,
  action: IUserAction,
): IUserProviderState => {
  switch (action.type) {
    case UserActions.SET_USER: {
      return { ...state, data: action.payload };
    }
    case UserActions.LOGOUT_USER: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
};
