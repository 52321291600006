import createProvider from '@packages/provider/Provider';

import { INotification } from '@interfaces/index';

import notificationsReducer, {
  INotificationsProviderState,
  defaultState,
} from './reducer';

export const {
  Provider: NotificationsProvider,
  useState: useNotificationsState,
  useDispatch: useNotificationsDispatch,
  getDispatch: getNotificationsDispatch,
  getState: getNotificationsState,
  dispatch: notificationsDispatch,
} = createProvider<INotificationsProviderState>(
  notificationsReducer,
  defaultState,
);

export const useNotification = (): INotification[] =>
  useNotificationsState().data;
